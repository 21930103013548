import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Box, Button, CircularProgress, Grid, ThemeProvider, createTheme } from '@mui/material';
import { green, blueGrey, grey } from '@mui/material/colors';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import { Google } from '@mui/icons-material';

const root_node = document.getElementById('root');
const root = ReactDOM.createRoot(root_node);

const theme = createTheme({
    'palette': {
        'primary': green,
        'secondary': {
            'main': blueGrey[600],
            'light': grey[300],
            'dark': grey[800],
        },
    },
});

function GoogleLoginOverlay() {
    async function onAuthTest(access_token) {
        const api_endpoint = window.location.hostname === 'localhost' ? 'http://localhost:8080' : 'https://cpt.mobilefuse.com';

        const response = await fetch(`${api_endpoint}/ping?cache_busting=${window.crypto.randomUUID()}`, {
            'headers': {
                'Authorization': `Bearer ${access_token}`,
            },
        });
        const status = response.status;

        if (status !== 200) {
            localStorage.removeItem('google_token');

            setGoogleToken(null);
            setAuthorized(false);

            return;
        }

        localStorage.setItem('google_token', access_token);

        setGoogleToken(access_token);
        setAuthorized(true);
    }

    const [google_token, setGoogleToken] = React.useState(localStorage.getItem('google_token') ?? null);
    const [authorized, setAuthorized]    = React.useState(false);
    const [auth_loading, setAuthLoading] = React.useState(false);

    const google_login = useGoogleLogin({
        'onSuccess': (response) => onAuthTest(response.access_token),
        'onError': (error) => {
            console.error(error);

            setAuthLoading(false);
            setAuthorized(false);
        },
        'hosted_domain': 'mobilefuse.com',
        'prompt': '',
    });

    React.useLayoutEffect(() => {
        // Try to sign-in automatically with the saved Google access token:

        if (!google_token || auth_loading) {
            return;
        }

        onAuthTest(google_token);

        setAuthorized(false);
    }, [google_token, auth_loading]);

    if (authorized && google_token) {
        return <App google_token={ google_token }/>
    }

    return <Grid container style={{
        'width': 'fit-content',
        'height': 'fit-content',
        'position': 'absolute',
        'top': '50%',
        'transform': 'translateY(-50%)',
    }} gap={ 2 }>
        <Grid
            item
            xs={ 12 }
            textAlign='center'
        >
            <Box component='img' src='/images/mobilefuse.png' width='64px' height='64px' sx={{
                'userSelect': 'none',
            }} draggable='false'/>
        </Grid>

        <Grid
            item
            xs={ 12 }
            display='flex'
            justifyContent='center'
        >
            { !auth_loading ? <Button
                variant='outlined'
                color='success'
                disabled={ auth_loading }
                onClick={ () => google_login() }
            >
                Sign in With Google&nbsp;&nbsp;<Google/>
            </Button> : <CircularProgress /> }
        </Grid>
    </Grid>;
};

root.render(<React.StrictMode>
    <GoogleOAuthProvider clientId={ process.env.REACT_APP_GOOGLE_CLIENT_ID }>
        <ThemeProvider theme={ theme }>
            <GoogleLoginOverlay/>
        </ThemeProvider>
    </GoogleOAuthProvider>
</React.StrictMode>);
