import React from 'react';
import * as echarts from 'echarts';
import './App.css';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider, Drawer, FormControl, Grid, LinearProgress, Link, MenuItem, MobileStepper, Select, Skeleton, Slider, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Toolbar, Tooltip, Typography,
} from '@mui/material';
import {
    AdUnits, Android, Apple,Devices, ExpandMore, FactoryOutlined, Female, Forest,
    HelpOutline, InfoOutlined, Insights, KeyboardArrowLeft, KeyboardArrowRight,
    LightbulbOutlined, LocationCity, Male, Paid, Settings, SourceOutlined, WebAssetOffOutlined,
} from '@mui/icons-material';

function App(props) {
    const google_token = props['google_token'];

    const [data_timestamp, setDataTimestamp]         = React.useState(null);
    const [data_source, setDataSource]               = React.useState('core');
    const [industry, setIndustry]                    = React.useState('All');
    const [kpi, setKpi]                              = React.useState('CTR');
    const [media_type, setMediaType]                 = React.useState('Banner');
    const [device_type, setDeviceType]               = React.useState('Any');
    const [time_of_day, setTimeOfDay]                = React.useState('Afternoon');
    const [imp_amount_weight, setImpAmountWeight]    = React.useState(0.25);
    const [app_cat_max_length, setAppCatMaxLength]   = React.useState(4);
    const [area_filter, setAreaFilter]               = React.useState('Disabled');
    const [age_filter, setAgeFilter]                 = React.useState(['Disabled']);
    const [hhi_filter, setHhiFilter]                 = React.useState(['Disabled']);
    const [weather_filter, setWeatherFilter]         = React.useState(['Disabled']);
    const [temperature_filter, setTemperatureFilter] = React.useState(['Disabled']);

    const [main_topic, setMainTopic]                = React.useState('time_of_day');
    const [profile, setProfile]                     = React.useState(0);
    const [is_content_loading, setIsContentLoading] = React.useState(false);
    const [is_data_loading, setIsDataLoading]       = React.useState(false);

    const [industry_data, setIndustryData]          = React.useState(null);
    const [user_profiles_data, setUserProfilesData] = React.useState(null);
    const [app_cat_data, setAppCatData]             = React.useState(null);
    const [poi_cat_data, setPoiCatData]             = React.useState(null);
    const [weather_data, setWeatherData]            = React.useState(null);
    const [time_of_day_data, setTimeOfDayData]      = React.useState(null); 

    const [recommended_time_of_day,  setRecommendedTimeOfDay]        = React.useState(null);
    const [recommended_user_profiles, setRecommendedUserProfiles]    = React.useState(null);
    const [recommended_app_categories, setRecommendedAppCategories]  = React.useState(null);
    const [recommended_poi_categories, setRecommendedPoiCategories]  = React.useState(null);
    const [recommended_weather, setRecommendedWeather]               = React.useState(null);
    const [recommended_weather_per_tod, setRecommendedWeatherPerTod] = React.useState(null);
    const [top_weather_recommendation, setTopWeatherRecommendation]  = React.useState(null);
    const [morning_kpi_value, setMorningKpiValue]                    = React.useState(null);
    const [afternoon_kpi_value, setAfternoonKpiValue]                = React.useState(null);
    const [night_kpi_value, setNightKpiValue]                        = React.useState(null);
    const [top_gender, setTopGender]                                 = React.useState(null);
    const [top_os, setTopOs]                                         = React.useState(null);
    const [top_gender_pct, setTopGenderPct]                          = React.useState(null);
    const [top_os_pct, setTopOsPct]                                  = React.useState(null);

    const media_type_lc = media_type.toLowerCase();
    const kpi_lc        = kpi.toLowerCase();

    const time_of_day_plot_ref = React.useRef();

    const kpi_display_value = {
        'CTR': 'Click-Through Ratio',
        'VCR': 'Video Completion Rate',
        'ER': 'Engagement Rate',
    }[kpi];

    const parameters = React.useRef({
        'media_type': [
            'Banner',
            'Video',
        ],
        'device_type': {
            'mfx_insights': [
                'Any',
                'Phone',
                'Tablet',
                'Connected TV',
            ],
            'core': [
                'Any',
                'Mobile',
                'Connected TV',
            ],
        },
        'industry': [
            // This is going to be updated with available options once we pull data from the API.
            'All',
        ],
        'age_filter': [
            '< 18',
            '18-24',
            '25-34',
            '35-44',
            '45-54',
            '55-64',
            '65+',
        ],
        'hhi_filter': [
            '< 30K',
            '30K - 50K',
            '50K - 75K',
            '75K - 100K',
            '100K - 150K',
            '150K - 200K',
            '> 200K',
        ],
        'weather_filter': [
            'Cloudy',
            'Rainy',
            'Sunny',
            'Snowy',
        ],
        'temperature_filter': [
            'Hot',
            'Warm',
            'Cold',
        ],
    });

    const plots = React.useRef({
        'time_of_day': null,
    });

    function initPlot(ref, plot_id, options) {
        if (!ref) {
            return;
        }

        plots.current[plot_id] = echarts.init(ref, '');
        window.addEventListener('resize', () => plots.current[plot_id].resize());

        updatePlot(plot_id, options);
    }

    function updatePlot(plot_id, options) {
        plots.current[plot_id].setOption(options);

        window.requestAnimationFrame(() => {
            window.requestAnimationFrame(() => {
                plots.current[plot_id].resize();
            });
        });
    }

    function onSelectChange(e, param, setter) {
        if (!Array.isArray(e.target.value)) {
            setter(e.target.value);
            return;
        }

        if (!e.target.value || e.target.value.length === 0) {
            setter(['Disabled']);
            return;
        }

        if (JSON.stringify(e.target.value) === '["Disabled"]') {
            setter(['Disabled']);
            return;
        }

        const values = [... e.target.value];
        const index = values.indexOf('Disabled');

        if (index === values.length - 1) {
            setter(['Disabled']);
            return;
        }

        if (index > -1) {
            values.splice(index, 1);
        } else if (e.target.value.length === parameters.current[param].length) {
            setter(['Disabled']);
            return;
        }

        setter(values);
    }

    function onDataSourceChange(e) {
        onSelectChange(e, 'data_source', (value) => {
            setDataTimestamp(null);
            setIndustry('All');
            setDeviceType('Any');
            setKpi('CTR');
            setMediaType('Banner');
            setMainTopic('time_of_day');

            setDataSource(value);
        });
    }

    function onKpiChange(e) {
        onSelectChange(e, 'kpi', setKpi);
    }

    function onMediaTypeChange(e) {
        onSelectChange(e, 'media_type', setMediaType);
    }

    function onIndustryChange(e) {
        onSelectChange(e, 'industry', setIndustry);
    }

    function onDeviceTypeChange(e) {
        onSelectChange(e, 'device_type', setDeviceType);
    }

    function getTimeOfDayPlotOptions(params) {
        return {
            'tooltip': {
                'trigger': 'axis',
            },
            'toolbox': {
                'show': false,
                'feature': {
                    'magicType': { type: ['line', 'bar'] },
                },
              },
            'legend': {},
            'xAxis': {
                'type': 'category',
                'boundaryGap': false,
                'data': ['Morning', 'Afternoon', 'Evening / Night'],
                'offset': 12,
            },
            'yAxis': {
                'type': 'value',
                'axisLabel': {
                    'formatter': '{value} %',
                },
            },
            'series': [{
                'type': 'line',
                'data': [
                    {
                        'value': params[0],
                        'itemStyle': {
                            'color': '#ffcd80',
                        },
                    },
                    {
                        'value': params[1],
                        'itemStyle': {
                            'color': '#fd9616',
                        },
                    },
                    {
                        'value': params[2],
                        'itemStyle': {
                            'color': '#313163',
                        },
                    },
                ],
            }],
            'color': 'rgb(97, 187, 94)',
        };
    }

    function getPlotOptions(topic, params) {
        if (topic === 'time_of_day') {
            return getTimeOfDayPlotOptions(params);
        }

        return null;
    }

    React.useLayoutEffect(() => {
        if (media_type === 'Banner' && kpi === 'VCR') {
            setKpi('CTR');
            return;
        }

        if (kpi === 'VCR' && media_type !== 'Video') {
            setMediaType('Video');
            return;
        }
    }, [media_type, kpi]);

    // Pull data from the API:
    React.useLayoutEffect(() => {
        if (is_data_loading) {
            return;
        }

        setIsDataLoading(true);
        setUserProfilesData(null);
        setAppCatData(null);
        setPoiCatData(null);
        setTimeOfDayData(null);
        setWeatherData(null);
        setMainTopic('time_of_day');

        (async() => {
            const api_url       = window.location.hostname === 'localhost' ? 'http://localhost:8080' : 'https://cpt.mobilefuse.com';
            const industry_enc  = encodeURIComponent(industry);
            const cache_busting = window.crypto.randomUUID();
            const fetch_options = {
                'headers': {
                    'Authorization': `Bearer ${google_token}`,
                },
            };

            const responses = await Promise.all([
                fetch(api_url + `/data-timestamp?data_source=${data_source}&cache_busting=${cache_busting}`, fetch_options),
                fetch(api_url + `/industry-data?data_source=${data_source}&cache_busting=${cache_busting}`, fetch_options),
                fetch(api_url + `/user-profiles?data_source=${data_source}&industry=${industry_enc}&cache_busting=${cache_busting}`, fetch_options),
                fetch(api_url + `/app-cat?data_source=${data_source}&industry=${industry_enc}&cache_busting=${cache_busting}`, fetch_options),
                fetch(api_url + `/poi-cat?data_source=${data_source}&industry=${industry_enc}&cache_busting=${cache_busting}`, fetch_options),
                fetch(api_url + `/time-of-day?data_source=${data_source}&industry=${industry_enc}&cache_busting=${cache_busting}`, fetch_options),
                fetch(api_url + `/weather?data_source=${data_source}&industry=${industry_enc}&cache_busting=${cache_busting}`, fetch_options),
            ]);

            if (responses.some((response) => response.status === 401)) {
                // Go back to sign in:
                localStorage.removeItem('google_token');
                window.location.reload();

                return;
            }

            const json = await Promise.all([
                responses[0].json(),
                responses[1].json(),
                responses[2].json(),
                responses[3].json(),
                responses[4].json(),
                responses[5].json(),
                responses[6].json(),
            ]);

            const industry_data = json[1];

            parameters.current['industry'] = Object.keys(industry_data).filter((key) => key !== 'All' && key !== 'Other');

            parameters.current['industry'].sort();
            parameters.current['industry'] = [
                'All',
                'Other',
                ... parameters.current['industry'],
            ];

            setDataTimestamp(json[0]['data_timestamp']);
            setIndustryData(industry_data);
            setUserProfilesData(json[2]);
            setAppCatData(json[3]);
            setPoiCatData(json[4]);
            setTimeOfDayData(json[5]);
            setWeatherData(json[6]);
            setIsDataLoading(false);
        })();
    }, [
        data_source,
        industry,
    ]);

    React.useLayoutEffect(() => {
        if (is_content_loading) {
            return;
        }

        const has_all_data =
            industry_data         !== null
            && user_profiles_data !== null
            && app_cat_data       !== null
            && poi_cat_data       !== null
            && weather_data       !== null
            && time_of_day_data   !== null;

        if (!has_all_data) {
            return;
        }

        setIsContentLoading(true);
        setRecommendedUserProfiles(null);
        setRecommendedAppCategories(null);
        setRecommendedPoiCategories(null);
        setRecommendedWeatherPerTod(null);
        setRecommendedWeather(null);
        setTopWeatherRecommendation(null);
        setRecommendedTimeOfDay(null);
        setTimeOfDay(null);
        setProfile(0);

        const l_industry     = industry;
        const l_media_type   = media_type_lc;
        const l_device_type  = device_type.toLowerCase();
        const l_kpi          = kpi_lc;

        let user_profiles       = null;
        let app_categories      = null;
        let poi_categories      = null;
        let weather_time_of_day = null;
        let weather             = null;

        // Sort based on KPI value and Impression amount:
        const fn_sort = (o1, o2) => {
            const imp_weight = imp_amount_weight * 0.25;

            const kpi1 = Number(o1[l_kpi]);
            const kpi2 = Number(o2[l_kpi]);

            const imp1 = Number(o1['imps']);
            const imp2 = Number(o2['imps']);

            const imp_score_1 = imp1 > imp2 ? 1 : 0;
            const imp_score_2 = imp2 > imp1 ? 1 : 0;

            const score1 = kpi1 + (kpi1 * imp_score_1 * imp_weight);
            const score2 = kpi2 + (kpi2 * imp_score_2 * imp_weight);

            return score2 - score1;
        };

        // Transform weather recommendations:
        try {
            weather = JSON.parse(JSON.stringify(weather_data[l_industry][l_media_type][l_device_type]));

            weather = weather.map((o) => {
                const parts = o['weather_conditions'].split('_');

                o['weather']     = parts[0];
                o['temperature'] = parts[1];

                return o;
            });

            if (JSON.stringify(weather_filter) !== '["Disabled"]') {
                weather = weather.filter((o) => weather_filter.includes(o['weather']));
            }

            if (JSON.stringify(temperature_filter) !== '["Disabled"]') {
                weather = weather.filter((o) => temperature_filter.includes(o['temperature']));
            }

            weather.sort(fn_sort);

            if (weather.length > 5) {
                weather.length = 5;
            }
        } catch (e) {}

        try {
            weather_time_of_day = [
                JSON.parse(JSON.stringify(weather_data[l_industry][l_media_type][l_device_type]['morning'])),
                JSON.parse(JSON.stringify(weather_data[l_industry][l_media_type][l_device_type]['afternoon'])),
                JSON.parse(JSON.stringify(weather_data[l_industry][l_media_type][l_device_type]['night'])),
            ];

            weather_time_of_day = weather_time_of_day.filter((e) => e !== undefined && e !== null);

            for (let i = 0; i < weather_time_of_day.length; i++) {
                let weather = weather_time_of_day[i];

                weather = weather.map((o) => {
                    const parts = o['weather_conditions'].split('_');

                    o['weather']     = parts[0];
                    o['temperature'] = parts[1];

                    return o;
                });

                if (JSON.stringify(weather_filter) !== '["Disabled"]') {
                    weather = weather.filter((o) => weather_filter.includes(o['weather']));
                }

                if (JSON.stringify(temperature_filter) !== '["Disabled"]') {
                    weather = weather.filter((o) => temperature_filter.includes(o['temperature']));
                }

                weather.sort(fn_sort);

                if (weather.length > 5) {
                    weather.length = 5;
                }

                weather_time_of_day[i] = weather;
            }

            weather_time_of_day = weather_time_of_day.filter((e) => e.length > 0);
        } catch (e) {}

        // Transform user profiles:
        try {
            user_profiles  = user_profiles_data[l_industry][l_media_type][l_device_type];

            user_profiles = user_profiles.map((o) => {
                const parts = o['user_profile'].split('_');
                o['area'] = parts[0];
                o['age']  = parts[1];
                o['hhi']  = parts[2];

                return o;
            });

            if (area_filter !== 'Disabled') {
                user_profiles = user_profiles.filter((o) => o['area'] === area_filter);
            }

            if (data_source === 'mfx_insights' && JSON.stringify(age_filter) !== '["Disabled"]') {
                user_profiles = user_profiles.filter((o) => age_filter.includes(o['age']));
            }

            if (JSON.stringify(hhi_filter) !== '["Disabled"]') {
                user_profiles = user_profiles.filter((o) => hhi_filter.includes(o['hhi']));
            }

            user_profiles.sort(fn_sort);

            user_profiles = user_profiles.slice(0, 20);
            user_profiles = user_profiles.filter((o) => o[l_kpi] >= 0);

            user_profiles = user_profiles.map((o) => {
                return {
                    'ctr': o['ctr'].toFixed(3),
                    'vcr': o['vcr'].toFixed(3),
                    'er': o['er'].toFixed(3),
                    'area': o['area'],
                    'age': o['age'] === 'NULL' ? null : o['age'],
                    'hhi': o['hhi'],
                    'imps': o['imps'],
                    'females': o['gender_female'] ? o['gender_female'].toFixed(1) : null,
                    'males': o['gender_male'] ? o['gender_male'].toFixed(1) : null,
                    'android': o['os_android'] ? o['os_android'].toFixed(1) : null,
                    'ios': o['os_ios'] ? o['os_ios'].toFixed(1) : null,
                };
            });
        } catch (e) {}

        // Transform app cat.:
        try {
            app_categories = app_cat_data[l_industry][l_media_type][l_device_type];
            app_categories = app_categories.filter((o) =>
                o['app_cat_string'].split('|').length - 1 < app_cat_max_length
            );

            app_categories.sort(fn_sort);

            app_categories = app_categories.slice(0, 20);
            app_categories = app_categories.filter((o) => o[l_kpi] >= 0);

            app_categories = app_categories.map((o) => {
                const parts       = o['app_cat_string'].split('|');
                let app_cat_codes = [];
                let app_cat_names = [];

                for (const part of parts) {
                    const app_cat_parts = part.split(' - ');
                    const app_cat_code  = app_cat_parts[0];
                    const app_cat_name  = app_cat_parts[1];

                    if (app_cat_code === 'MF') {
                        app_cat_codes.push('');
                        app_cat_names.push('(MF) ' + app_cat_name);

                        continue;
                    }

                    app_cat_codes.push(app_cat_code);
                    app_cat_names.push(app_cat_name);
                }
    
                app_cat_codes = app_cat_codes.join("\r\n");
                app_cat_names = app_cat_names.join("\r\n");

                return {
                    'ctr': o['ctr'].toFixed(3),
                    'vcr': o['vcr'].toFixed(3),
                    'er': o['er'].toFixed(3),
                    'app_cat_codes': app_cat_codes,
                    'app_cat_names': app_cat_names,
                };
            });
        } catch (e) {}

        // Transform POIs:
        try {
            poi_categories = poi_cat_data[l_industry][l_media_type][l_device_type];

            poi_categories.sort(fn_sort);

            poi_categories = poi_categories.slice(0, 20);
            poi_categories = poi_categories.filter((o) => o[l_kpi] >= 0);

            poi_categories = poi_categories.map((o) => {
                // Fix hyphens:
                let poi_cat = o['poi_cat'].replace('- ', '-').split('-').join(' - ');

                // Transform every word's first letter to uppercase:
                poi_cat = poi_cat.split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');

                return {
                    'ctr': o['ctr'].toFixed(3),
                    'vcr': o['vcr'].toFixed(3),
                    'er': o['er'].toFixed(3),
                    'category': poi_cat,
                };
            });
        } catch (e) {}

        try {
            const morning_kpi   = Number(time_of_day_data[l_industry][l_media_type][l_device_type]['morning'][l_kpi]);
            const afternoon_kpi = Number(time_of_day_data[l_industry][l_media_type][l_device_type]['afternoon'][l_kpi]);
            const night_kpi     = Number(time_of_day_data[l_industry][l_media_type][l_device_type]['night'][l_kpi]);

            const recommended_time_of_day = [{
                'time_of_day': 'Morning',
                'value': morning_kpi,
            }, {
                'time_of_day': 'Afternoon',
                'value': afternoon_kpi,
            }, {
                'time_of_day': 'Night',
                'value': night_kpi,
            }].sort((o1, o2) => o1['value'] > o2['value'] ? -1 : 1)[0]['time_of_day'];

            setMorningKpiValue(morning_kpi.toFixed(3));
            setAfternoonKpiValue(afternoon_kpi.toFixed(3));
            setNightKpiValue(night_kpi.toFixed(3));

            setRecommendedTimeOfDay(recommended_time_of_day);
            setTimeOfDay(recommended_time_of_day);
        } catch (e) {}

        if (user_profiles && user_profiles.length > 0) {
            setRecommendedUserProfiles(user_profiles);
        }

        if (app_categories && app_categories.length > 0) {
            setRecommendedAppCategories(app_categories);
        }

        if (poi_categories && poi_categories.length > 0) {
            setRecommendedPoiCategories(poi_categories);
        }

        if (data_source === 'mfx_insights' && weather_time_of_day && weather_time_of_day.length === 3) {
            const weather_per_tod = {
                'Morning': weather_time_of_day[0],
                'Afternoon': weather_time_of_day[1],
                'Night': weather_time_of_day[2],
            };

            setRecommendedWeatherPerTod(weather_per_tod);
            setTopWeatherRecommendation(weather_per_tod[time_of_day ?? recommended_time_of_day ?? 'Afternoon'][0]);
        } else if (data_source ==='core' && weather) {
            setRecommendedWeather(weather);
            setTopWeatherRecommendation(weather[0]);
        }

        setIsContentLoading(false);
    }, [
        data_source,
        industry,
        media_type,
        device_type,
        kpi,
        imp_amount_weight,
        app_cat_max_length,
        JSON.stringify(industry_data),
        JSON.stringify(app_cat_data),
        JSON.stringify(poi_cat_data),
        JSON.stringify(user_profiles_data),
        JSON.stringify(time_of_day_data),
        JSON.stringify(weather_data),
        JSON.stringify(area_filter),
        JSON.stringify(age_filter),
        JSON.stringify(hhi_filter),
        JSON.stringify(weather_filter),
        JSON.stringify(temperature_filter),
    ]);

    React.useLayoutEffect(() => {
        if (!user_profiles_data || !user_profiles_data[industry] || !user_profiles_data[industry]['all']) {
            setTopGender(null);
            setTopOs(null);
            setTopGenderPct(null);
            setTopOsPct(null);

            return;
        }

        const profile = user_profiles_data[industry]['all']['all'][0];

        const top_gender = profile['gender_male'] > profile['gender_female'] ? 'gender_male' : 'gender_female';
        const top_os     = profile['os_android']  > profile['os_ios'] ? 'os_android' : 'os_ios';

        setTopGender(top_gender === 'gender_male' ? 'Male' : 'Female');
        setTopOs(top_os === 'os_android' ? 'Android' : 'iOS');

        setTopGenderPct(profile[top_gender].toFixed(1));
        setTopOsPct(profile[top_os].toFixed(1));
    }, [
        data_source,
        industry,
        JSON.stringify(user_profiles_data),
    ]);

    if (morning_kpi_value && afternoon_kpi_value && night_kpi_value && main_topic === 'time_of_day') {
        window.setTimeout(() => {
            const plot_options = getPlotOptions('time_of_day', [
                morning_kpi_value,
                afternoon_kpi_value,
                night_kpi_value,
            ]);

            initPlot(time_of_day_plot_ref.current, 'time_of_day', plot_options);
        }, 0);
    }

    const no_data_alert = <Grid
        item
        xs={ 8 }
        mt={ 1 }
        py={ 2 }
    >
        <Alert 
            color='secondary'
            variant='outlined'
            icon={ false }
            sx={{
                'textAlign': 'center',
                'width': '495px',
                'height': '323px',
                'alignItems': 'center',
            }}
        >
            <b>Sorry!</b> Looks like we don't have enough data to show any useful recommendations now.<br/>
            Please try again using different inputs and review your <b>Advanced Options</b> selections.
        </Alert>
    </Grid>;

    return <React.Fragment>
        { (is_content_loading || is_data_loading) && <Box
            width='100%'
            height='3px'
            position='absolute'
            left='0'
            top='0'
        >
            <LinearProgress sx={{
                'width': '100%',
                'height': '100%',
            }}/>
        </Box> }

        <Grid container style={{
            'minWidth': '1152px',
            'maxWidth': '1152px',
        }}>
            <Grid item xs={ 3 } sx={{
                'backgroundColor': 'transparent',
            }}>
                <Drawer sx={{
                    'width': '100%',
                    'height': '100%',
                    '& .MuiDrawer-paper': {
                        'width': '100%',
                        'height': '100%',
                        'boxSizing': 'border-box',
                        'position': 'relative',
                        'backgroundColor': 'rgba(255, 255, 255, 0)',
                    },
                }} variant='permanent' anchor='left'>
                    <Toolbar sx={{
                        'justifyContent': 'center',
                        'my': 1,
                        'mx': 1,
                    }}>
                        <Box component='img' src='/images/mobilefuse.png' width='64px' height='64px' sx={{
                            'userSelect': 'none',
                        }} draggable='false'/>
                    </Toolbar>

                    <Divider/>

                    <Grid container gap={ 3 } mb={ 1 }>
                        <Grid item xs={ 12 } mt={ 2 }>
                            <Grid container>
                                <Grid item xs={ 12 } display='inline-flex' justifyContent='start' alignItems='center'>
                                    <SourceOutlined/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;<Typography>Data Source</Typography>
                                </Grid>

                                <Grid item xs={ 12 } textAlign='center' mt={ 0.5 }>
                                    <FormControl size='small' sx={{
                                        'm': 0,
                                        'width': '185px',
                                    }}>
                                        <Select
                                            defaultValue={ 'core' }
                                            onChange={ onDataSourceChange }
                                            value={ data_source }
                                            disabled={ is_content_loading || is_data_loading }
                                        >
                                            <MenuItem value='core'>Core</MenuItem>
                                            <MenuItem value='mfx_insights'>MFX Insights</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Divider sx={{ 'mt': 3 }}/>
                        </Grid>

                        <Grid item xs={ 12 }>
                            <Grid container>
                                <Grid item xs={ 12 } display='inline-flex' justifyContent='start' alignItems='center'>
                                    <FactoryOutlined/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;<Typography>Industry Category</Typography>
                                </Grid>

                                <Grid item xs={ 12 } textAlign='center' mt={ 0.5 }>
                                    <FormControl size='small' sx={{
                                        'm': 0,
                                        'width': '185px',
                                    }}>
                                        <Select
                                            defaultValue={ 'All' }
                                            onChange={ onIndustryChange }
                                            value={ industry }
                                            disabled={ is_content_loading || is_data_loading }
                                        >
                                            { parameters.current['industry'].map((cat) =>
                                                <MenuItem key={ cat } value={ cat }>{ cat }</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Divider sx={{ 'mt': 3 }}/>
                        </Grid>

                        <Grid item xs={ 12 }>
                            <Tooltip
                                title='Media Type selection is disabled because you selected "Video Completion Rate" as your KPI.'
                                disableHoverListener={ kpi !== 'VCR' }
                                disableFocusListener={ kpi !== 'VCR' }
                                disableTouchListener={ kpi !== 'VCR' }
                            >
                                <Grid container>
                                    <Grid item xs={ 12 }
                                        display='inline-flex'
                                        justifyContent='start'
                                        alignItems='center'
                                        sx={{
                                            'opacity': kpi === 'VCR' ? 0.5 : 1,
                                        }}
                                    >
                                        <AdUnits/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;<Typography>Media Type</Typography>
                                    </Grid>

                                    <Grid item xs={ 12 } textAlign='center' mt={ 0.5 }>
                                        <FormControl size='small' sx={{
                                            'm': 0,
                                            'width': '185px',
                                        }}>
                                            <Select
                                                defaultValue={ 'Banner' }
                                                onChange={ onMediaTypeChange }
                                                value={ media_type }
                                                disabled={ kpi === 'VCR' || is_content_loading || is_data_loading }
                                            >
                                                { parameters.current['media_type'].map((value) => <MenuItem value={ value } key={ value }>
                                                    { value }
                                                </MenuItem>) }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Tooltip>

                            <Divider sx={{ 'mt': 3 }}/>
                        </Grid>

                        <Grid item xs={ 12 }>
                            <Grid container>
                                <Grid item xs={ 12 } display='inline-flex' justifyContent='start' alignItems='center'>
                                    <Devices/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;<Typography>Device Type</Typography>
                                </Grid>

                                <Grid item xs={ 12 } textAlign='center' mt={ 0.5 }>
                                    <FormControl size='small' sx={{
                                        'm': 0,
                                        'width': '185px',
                                    }}>
                                        <Select
                                            defaultValue={ 'Any' }
                                            onChange={ onDeviceTypeChange }
                                            value={ device_type }
                                            disabled={ is_content_loading || is_data_loading }
                                        >
                                            { parameters.current['device_type'][data_source].map((value) => <MenuItem value={ value } key={ value }>
                                                { value }
                                            </MenuItem>) }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Divider sx={{ 'mt': 3 }}/>
                        </Grid>

                        <Grid item xs={ 12 }>
                            <Grid container>
                                <Grid item xs={ 12 }
                                    display='inline-flex'
                                    justifyContent='start'
                                    alignItems='center'
                                >
                                    <Insights/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;<Typography>KPI</Typography>
                                </Grid>

                                <Grid item xs={ 12 } textAlign='center' mt={ 0.5 }>
                                    <FormControl size='small' sx={{
                                        'm': 0,
                                        'width': '185px',
                                    }}>
                                        <Select
                                            defaultValue={ 'CTR' }
                                            onChange={ onKpiChange }
                                            value={ kpi }
                                            disabled={ is_content_loading || is_data_loading }
                                        >
                                            <MenuItem value='CTR'>CTR - Click Through Rate</MenuItem>
                                            { media_type === 'Video' && <MenuItem value='VCR'>VCR - Video Completion Rate</MenuItem> }
                                            <MenuItem value='ER'>ER - Engagement Rate</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Divider sx={{ 'mt': 3 }}/>
                        </Grid>

                        <Grid item xs={ 12 }>
                            <Grid container>
                                <Accordion
                                    square
                                    disableGutters
                                    elevation={ 0 }
                                >
                                    <AccordionSummary
                                        expandIcon={ <ExpandMore/> }
                                        style={{
                                            'paddingLeft': 0,
                                        }}
                                    >
                                        <Grid item xs={ 12 } display='inline-flex' justifyContent='start' alignItems='center'>
                                            <Settings/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Typography><b>Advanced Options</b></Typography>
                                        </Grid>
                                    </AccordionSummary>

                                    { /* Advanced Options */ }
                                    <AccordionDetails>
                                        <Grid item xs={ 12 } mt={ 1 } pl={ 3 }>
                                            <Grid container>

                                                { /* Impression Amount Weight Option */ }
                                                <Grid item xs={ 12 }>
                                                    <Grid container>
                                                        <Grid item xs={ 12 } display='inline-flex' justifyContent='space-between'>
                                                            <Typography>Imp. Amount Weight</Typography>
                                                            <Box>
                                                                <Tooltip
                                                                    title='Adjust how important the number of impressions is when calculating recommendations. Higher values (weight) favour recommendations with more impressions, lower favour higher KPIs.'
                                                                >
                                                                    <HelpOutline/>
                                                                </Tooltip>
                                                            </Box>
                                                        </Grid>

                                                        <Grid item
                                                            xs={ 12 }
                                                            mt={ 0.5 }
                                                            display='inline-flex'
                                                            justifyContent='start'
                                                            alignItems='center'
                                                        >
                                                            <Typography sx={{
                                                                'mr': '18px',
                                                            }}>
                                                                <b>0</b>
                                                            </Typography>

                                                            <FormControl size='small' sx={{
                                                                'width': '100px',
                                                            }}>
                                                                <Slider
                                                                    valueLabelDisplay='auto'
                                                                    defaultValue={ 0.25 }
                                                                    step={ 0.25 }
                                                                    min={ 0 }
                                                                    max={ 1 }
                                                                    track={ false }
                                                                    onChange={ (e) => setImpAmountWeight(e.target.value) }
                                                                    disabled={ is_content_loading || is_data_loading }
                                                                />
                                                            </FormControl>

                                                            <Typography sx={{
                                                                'ml': '18px',
                                                            }}>
                                                                <b>1</b>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                { /* App Cat. Max. Length Option - Only applies to default source of data */ }
                                                { data_source === 'mfx_insights' && <Grid item xs={ 12 } mt={ 1 }>
                                                    <Grid container>
                                                        <Grid item xs={ 12 } display='inline-flex' justifyContent='space-between'>
                                                            <Typography>App Cat. Max. Len.</Typography>
                                                            <Box>
                                                                <Tooltip
                                                                    title='App category is usually a combination of multiple IAB categories. Set the max. number of categories per recommendation.'
                                                                >
                                                                    <HelpOutline/>
                                                                </Tooltip>
                                                            </Box>
                                                        </Grid>

                                                        <Grid item
                                                            xs={ 12 }
                                                            mt={ 0.5 }
                                                            display='inline-flex'
                                                            justifyContent='start'
                                                            alignItems='center'
                                                        >
                                                            <Typography sx={{
                                                                'mr': '18px',
                                                            }}>
                                                                <b>1</b>
                                                            </Typography>

                                                            <FormControl size='small' sx={{
                                                                'width': '100px',
                                                            }}>
                                                                <Slider
                                                                    valueLabelDisplay='auto'
                                                                    defaultValue={ 4 }
                                                                    step={ 1 }
                                                                    min={ 1 }
                                                                    max={ 5 }
                                                                    track={ false }
                                                                    onChange={ (e) => setAppCatMaxLength(e.target.value) }
                                                                    disabled={ is_content_loading || is_data_loading }
                                                                />
                                                            </FormControl>

                                                            <Typography sx={{
                                                                'ml': '18px',
                                                            }}>
                                                                <b>5</b>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid> }

                                                <Grid item xs={ 12 } mt={ 2 } display='inline-flex' justifyContent='space-between'>
                                                    <Typography><b>Output Filtering</b></Typography>

                                                    <Box>
                                                        <Tooltip
                                                            title='Only allow certain ouput values when calculating recommendations.'
                                                        >
                                                            <HelpOutline/>
                                                        </Tooltip>
                                                    </Box>
                                                </Grid>

                                                { /* Area Filter Option */ }
                                                <Grid item xs={ 12 } mt={ 2 }>
                                                    <Grid container>
                                                        <Grid item xs={ 12 }>
                                                            <Typography>Area (Population)</Typography>
                                                        </Grid>

                                                        <Grid item xs={ 12 } mt={ 0.5 }>
                                                            <FormControl size='small' sx={{
                                                                'm': 0,
                                                                'width': '155px',
                                                            }}>
                                                                <Select
                                                                    defaultValue='Disabled'
                                                                    onChange={ (e) => setAreaFilter(e.target.value) }
                                                                    value={ area_filter }
                                                                    disabled={ is_content_loading || is_data_loading }
                                                                >
                                                                    <MenuItem value='Disabled'>Disabled</MenuItem>
                                                                    <MenuItem value='Urban'>Only Urban</MenuItem>
                                                                    <MenuItem value='Rural'>Only Rural</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                { /* Age Filter Option */ }
                                                { data_source === 'mfx_insights' && <Grid item xs={ 12 } mt={ 2 }>
                                                    <Grid container>
                                                        <Grid item xs={ 12 }>
                                                            <Typography>Age Group</Typography>
                                                        </Grid>

                                                        <Grid item xs={ 12 } mt={ 0.5 }>
                                                            <FormControl size='small' sx={{
                                                                'm': 0,
                                                                'width': '155px',
                                                            }}>
                                                                <Select
                                                                    defaultValue='Disabled'
                                                                    onChange={ (e) => onSelectChange(e, 'age_filter', setAgeFilter) }
                                                                    value={ age_filter }
                                                                    disabled={ is_content_loading || is_data_loading }
                                                                    multiple
                                                                >
                                                                    <MenuItem value='Disabled'>Disabled</MenuItem>

                                                                    { parameters.current['age_filter'].map((value) => <MenuItem value={ value } key={ value }>
                                                                        { value }
                                                                    </MenuItem>) }
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid> }

                                                { /* HHi Filter Option */ }
                                                <Grid item xs={ 12 } mt={ 2 }>
                                                    <Grid container>
                                                        <Grid item xs={ 12 }>
                                                            <Typography>Househ. Income</Typography>
                                                        </Grid>

                                                        <Grid item xs={ 12 } mt={ 0.5 }>
                                                            <FormControl size='small' sx={{
                                                                'm': 0,
                                                                'width': '155px',
                                                            }}>
                                                                <Select
                                                                    defaultValue='Disabled'
                                                                    onChange={ (e) => onSelectChange(e, 'hhi_filter', setHhiFilter) }
                                                                    value={ hhi_filter }
                                                                    disabled={ is_content_loading || is_data_loading }
                                                                    multiple
                                                                >
                                                                    <MenuItem value='Disabled'>Disabled</MenuItem>

                                                                    { parameters.current['hhi_filter'].map((value) => <MenuItem value={ value } key={ value }>
                                                                        { value }
                                                                    </MenuItem>) }
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                { /* Weather Filter Option */ }
                                                <Grid item xs={ 12 } mt={ 2 }>
                                                    <Grid container>
                                                        <Grid item xs={ 12 }>
                                                            <Typography>Weather</Typography>
                                                        </Grid>

                                                        <Grid item xs={ 12 } mt={ 0.5 }>
                                                            <FormControl size='small' sx={{
                                                                'm': 0,
                                                                'width': '155px',
                                                            }}>
                                                                <Select
                                                                    defaultValue='Disabled'
                                                                    onChange={ (e) => onSelectChange(e, 'weather_filter', setWeatherFilter) }
                                                                    value={ weather_filter }
                                                                    disabled={ is_content_loading || is_data_loading }
                                                                    multiple
                                                                >
                                                                    <MenuItem value='Disabled'>Disabled</MenuItem>

                                                                    { parameters.current['weather_filter'].map((value) => <MenuItem value={ value } key={ value }>
                                                                        { value }
                                                                    </MenuItem>) }
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                { /* Temperature Filter Option */ }
                                                <Grid item xs={ 12 } mt={ 2 }>
                                                    <Grid container>
                                                        <Grid item xs={ 12 }>
                                                            <Typography>Temperature</Typography>
                                                        </Grid>

                                                        <Grid item xs={ 12 } mt={ 0.5 }>
                                                            <FormControl size='small' sx={{
                                                                'm': 0,
                                                                'width': '155px',
                                                            }}>
                                                                <Select
                                                                    defaultValue='Disabled'
                                                                    onChange={ (e) => onSelectChange(e, 'temperature_filter', setTemperatureFilter) }
                                                                    value={ temperature_filter }
                                                                    disabled={ is_content_loading || is_data_loading }
                                                                    multiple
                                                                >
                                                                    <MenuItem value='Disabled'>Disabled</MenuItem>

                                                                    { parameters.current['temperature_filter'].map((value) => <MenuItem value={ value } key={ value }>
                                                                        { value }
                                                                    </MenuItem>) }
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Grid>
                </Drawer>
            </Grid>

            <Grid item xs={ 9 }>
                <Grid container>
                    <Grid item
                        xs={ 12 }
                        mt={ 2 }
                        display='inline-flex'
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        <Typography
                            textAlign='left'
                            sx={{
                                'opacity': 0.3,
                                'fontSize': '14px',
                                'pl': 2.5,
                            }}
                        >
                            Rev. 2024-08-16
                        </Typography>

                        <Typography
                            textAlign='right'
                            sx={{
                                'opacity': 0.3,
                                'fontSize': '14px',
                                'pr': 2.5,
                            }}
                        >
                            Data timestamp:&nbsp;&nbsp;
                            { data_timestamp }
                        </Typography>
                    </Grid>

                    <Grid item xs={ 12 } mt={ 2 }>
                        <Typography variant='h5' textAlign='center'>
                            <span style={{ 'color': '#4caf50' }}><b>Campaign Planning Recommendations</b></span>
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={ 12 }
                        mt={ 1.5 }
                        display='none'
                        justifyContent='center'
                    >
                        <Alert
                            severity='success'
                            variant='outlined'
                            icon={ <InfoOutlined/> }
                            style={{
                                'width': 'fit-content',
                                'height': 'fit-content',
                                'paddingLeft': '16px',
                                'paddingRight': '16px',
                                'paddingBottom': '0px',
                            }}
                        >
                            Our recommendations are based on past MobileFuse data and selected inputs. We use 2 different sources of data:
                            <ul style={{
                                'marginTop': '8px',
                                'marginBottom': '4px',
                            }}>
                                <li>
                                    <Link component='button' onClick={ () => {
                                        if (is_data_loading || is_content_loading) {
                                            return;
                                        }

                                        setDataSource('core');
                                    }}>Core (default)</Link>
                                    &nbsp;- Includes all past and present Core aggregations. Large data volume provides improved certainty.
                                </li>

                                <li>
                                    <Link component='button' onClick={ () => {
                                        if (is_data_loading || is_content_loading) {
                                            return;
                                        }

                                        setDataSource('mfx_insights');
                                    }}>MFX&nbsp;&nbsp;Insights</Link>
                                    &nbsp;- Only MFX Insights harvested data. Currently lower in volume, but might provide more granularity.
                                </li>
                            </ul>
                        </Alert>
                    </Grid>

                    <Grid
                        item
                        xs={ 12 }
                        mt={ 3 }
                        px={ 6 }
                    >
                        <Box
                            width='100%'
                            height='80px'
                            mb={ 1 }
                            sx={{
                                'backgroundColor': 'rgba(255, 255, 255, 0)',
                                'borderBottom': '1px solid rgba(0, 0, 0, 0.12);',
                            }}
                        >
                            <Tabs
                                centered
                                value={ main_topic }
                                onChange={ (e, value) => setMainTopic(value) }
                                sx={{
                                    'pt': 1.5,
                                }}
                            >
                                <Tab label='Time of Day' value='time_of_day' disabled={ is_content_loading || is_data_loading }/>
                                <Tab label='Weather Conditions' value='conditions' disabled={ is_content_loading || is_data_loading }/>
                                <Tab label='User Profiles' value='user_profiles' disabled={ is_content_loading || is_data_loading }/>
                                <Tab label='App Categories' value='apps' disabled={ is_content_loading || is_data_loading }/>
                                <Tab label='Location' value='pois' disabled={ is_content_loading || is_data_loading }/>
                            </Tabs>
                        </Box>
                    </Grid>

                    <Grid
                        item
                        xs={ 4 }
                        mt={ 1 }
                        pl={ 6 }
                        pr={ 0 }
                        py={ 2 }
                    >
                        { is_data_loading && <Skeleton variant='rounded' width={ 208 } height={ 335 }/> }

                        { !is_data_loading &&  <Card
                            variant='outlined'
                            sx={{
                                'width': '208px',
                                'height': '335px',
                            }}
                        >
                            <CardHeader title='Industry' subheader='Avg. Metrics' sx={{
                                'textAlign': 'center',
                            }}/>

                            { industry_data && industry_data[industry] && industry_data[industry][media_type_lc] ? <CardContent>
                                <Table size='small' style={{ 'borderCollapse': 'separate' }}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                sx={{
                                                    'fontWeight': kpi === 'CTR' ? 800 : 400,
                                                }}
                                            >
                                                CTR
                                            </TableCell>

                                            <TableCell>
                                                { Number(industry_data[industry][media_type_lc]['ctr']).toFixed(3) }
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell
                                                sx={{
                                                    'fontWeight': kpi === 'VCR' ? 800 : 400,
                                                }}
                                            >
                                                VCR
                                            </TableCell>

                                            <TableCell>
                                                {
                                                    media_type === 'Video' &&
                                                    Number(industry_data[industry][media_type_lc]['vcr']).toFixed(3)
                                                }

                                                {
                                                    media_type !== 'Video' && 'N/A'
                                                }
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell
                                                sx={{
                                                    'fontWeight': kpi === 'ER' ? 800 : 400,
                                                }}
                                            >
                                                ER
                                            </TableCell>

                                            <TableCell>
                                                { Number(industry_data[industry][media_type_lc]['er']).toFixed(3) }
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>

                                <Alert severity='success' variant='outlined' icon={ false } sx={{
                                    'mt': 2,
                                }}>
                                    <Box>Data based on:</Box>

                                    <Box>
                                        <b>{
                                            new Intl.NumberFormat().format(Number(industry_data[industry][media_type_lc]['imps']))
                                        }</b>
                                    </Box>

                                    <Box>
                                        verified impressions.
                                    </Box>
                                </Alert>
                            </CardContent> : <CardContent sx={{
                                'display': 'flex',
                                'justifyContent': 'center',
                                'pt': '64px',
                            }}>
                                <WebAssetOffOutlined sx={{ 'fontSize': '58px' }}/>
                            </CardContent> }
                        </Card> }
                    </Grid>

                    { !is_data_loading && main_topic === 'time_of_day' && !recommended_time_of_day && no_data_alert }
                    { main_topic === 'time_of_day' && (recommended_time_of_day || is_data_loading || is_content_loading) && <Grid
                        item
                        xs={ 8 }
                        mt={ 1 }
                        py={ 2 }
                    >
                        <Alert
                            severity='info'
                            color='secondary'
                            variant='outlined'
                            icon={ (is_data_loading || is_content_loading) ? false : <LightbulbOutlined/> }
                            style={{
                                'width': '495px',
                                'height': '323px',
                                'position': 'relative',
                            }}
                        >
                            { (!is_data_loading && !is_content_loading) && <React.Fragment>
                                Consider targetting <b>{ recommended_time_of_day }</b> time for potentially improved <b>{ kpi_display_value }</b>.
                            </React.Fragment> }

                            <Box
                                width='495px'
                                height='280px'
                                sx={{
                                    'opacity': (is_data_loading || is_content_loading) ? '0' : '1',
                                }}
                                ref={ time_of_day_plot_ref }
                            />

                            { (is_data_loading || is_content_loading) && <CircularProgress sx={{
                                'position': 'relative',
                                'bottom': '50%',
                                'left': '45%',
                            }}/> }
                        </Alert>
                    </Grid> }

                    { main_topic === 'conditions' && !top_weather_recommendation && no_data_alert }
                    { main_topic ==='conditions' && top_weather_recommendation && <Grid
                        item
                        xs={ 4 }
                        mt={ 1 }
                        py={ 2 }
                    >
                        <Alert
                            severity='info'
                            color='secondary'
                            variant='outlined'
                            icon={ <LightbulbOutlined/> }
                            sx={{
                                'position': 'relative',
                                'width': '495px',
                                'height': '323px',
                            }}
                        >
                            { /* Display sun animation if top weather recommendation is Sunny */ }
                            { top_weather_recommendation['weather_conditions'].includes('Sunny') && <Box
                                position='absolute'
                                top='88px'
                                left='64px'
                                width='48px'
                                height='48px'
                                zIndex='-2'
                                sx={{
                                    'opacity': '0.7',
                                }}
                            >
                                <Box className='animated-sun'/>
                            </Box> }

                            { /* Display cloud animation if top weather recommendation is Cloudy */ }
                            { top_weather_recommendation['weather_conditions'].includes('Cloudy') && <Box
                                position='absolute'
                                left='33%'
                                top='75px'
                                zIndex='-2'
                                sx={{
                                    'opacity': 0.55,
                                }}
                            >
                                <Box className='animated-cloud' position='absolute' left='6%' top='80px'/>

                                <Box style={{
                                    'transform': 'scale(-0.5, 0.5)',
                                }}>
                                    <Box className='animated-cloud' position='absolute' left='2%' top='48px'/>
                                </Box>
                            </Box> }

                            { /* Display snow animation if top weather recommendation is Snowy */ }
                            { top_weather_recommendation['weather_conditions'].includes('Snowy') && <Box
                                position='absolute'
                                left='0'
                                top='0'
                                width='100%'
                                height='100%'
                                zIndex='-2'
                            >
                                <Box className='snow'><Box/></Box>
                            </Box> }

                            { /* Display rain animation if top weather recommendation is Rainy */ }
                            { top_weather_recommendation['weather_conditions'].includes('Rainy') && <Box
                                position='absolute'
                                left='0'
                                top='0'
                                width='100%'
                                height='100%'
                                zIndex='-2'
                                sx={{
                                    'opacity': '0.5',
                                }}
                            >
                                <Box className='rain'/>
                            </Box> }

                            <Box>
                                Take the following <b>Weather</b> recommendations into consideration:
                            </Box>

                            <Box
                                width='100%'
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                mt={ 4.5 }
                                py={ 2 }
                            >
                                { recommended_weather_per_tod && <Box>
                                    <Tabs
                                        orientation='vertical'
                                        value={ time_of_day }
                                        onChange={ (e, value) => setTimeOfDay(value) }
                                    >
                                        <Tab label='Morning' value='Morning'/>
                                        <Tab label='Afternoon' value='Afternoon'/>
                                        <Tab label='Evening / Night' value='Night'/>
                                    </Tabs>
                                </Box> }

                                <Box pl={ 8 } minHeight='202px'>
                                    <Table size='small' sx={{
                                        'borderCollapse': 'separate',
                                        'width': '260px',
                                    }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Weather</TableCell>
                                                <TableCell>{ kpi }</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            { (recommended_weather ?? recommended_weather_per_tod[time_of_day]).map((o, i) => <TableRow key={ i }>
                                                <TableCell>
                                                    { o['weather_conditions'].replace('_', ' & ') }
                                                </TableCell>

                                                <TableCell sx={{
                                                    'color': i === 0 ? '#4caf50' : 'initial',
                                                    'fontWeight': i === 0 ? '700' : '400',
                                                }}>
                                                    { Number(o[kpi_lc]).toFixed(3) }
                                                </TableCell>
                                            </TableRow>) }
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Box>
                        </Alert>
                    </Grid> }

                    { main_topic === 'user_profiles' && !recommended_user_profiles && no_data_alert }
                    { main_topic === 'user_profiles' && recommended_user_profiles && <Grid item
                        xs={ 8 }
                        mt={ 1 }
                        pr={ 4 }
                        pl={ 2 }
                        py={ 2 }
                    >
                        { data_source === 'core' && <Card variant='outlined' sx={{
                            'backgroundColor': 'rgba(255, 255, 255, 0.5)',
                            'width': '432px',
                            'height': '95px',
                            'mb': '10px',
                        }}>
                            <CardContent sx={{
                                'display': 'inline-flex',
                                'px': '12px',
                                'pt': '14px',
                            }}>
                                <LightbulbOutlined/>&nbsp;&nbsp;
                                <Typography>
                                    Most of the users in this category are <b>{ top_gender }s</b> ({ top_gender_pct }%), using <b>{ top_os }</b> ({ top_os_pct }%) as their operating system.
                                </Typography>
                            </CardContent>
                        </Card> }

                        <Card variant='outlined' sx={{
                            'backgroundColor': 'rgba(255, 255, 255, 0.5)',
                            'width': '432px',
                            'height': data_source === 'mfx_insights' ? '335px' : '230px',
                        }}>
                            <CardHeader
                                action={
                                    <Box px={ 0.5 } textAlign='center' width='80px'>
                                        <Typography variant='h5'><b>{ kpi }</b></Typography>
                                    </Box>
                                }
                                title={
                                    <Box px={ 0.5 } textAlign='center' width='80px'>
                                        <Typography variant='h5' color='primary'><b>{ recommended_user_profiles[profile][kpi_lc] }</b></Typography>
                                    </Box>
                                }
                                sx={{
                                    'justifyContent': 'center',
                                }}
                                style={{
                                    'paddingTop': '24px',
                                }}
                            />

                            <CardContent>
                                    <Table size='small' style={{ 'borderCollapse': 'separate' }}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell><b>Area (Population)</b></TableCell>
                                            <TableCell sx={{
                                                'display': 'flex',
                                                'alignItems': 'center',
                                                'width': '220px',
                                            }}>
                                                { recommended_user_profiles[profile]['area'] === 'Rural' ? <Forest/> : <LocationCity/> }
                                                &nbsp;&nbsp;{ recommended_user_profiles[profile]['area'] }
                                            </TableCell>
                                        </TableRow>

                                        { recommended_user_profiles[profile]['age'] && <TableRow>
                                            <TableCell><b>Age Group</b></TableCell>
                                            <TableCell sx={{
                                                'display': 'flex',
                                                'alignItems': 'center',
                                                'width': '220px',
                                            }}>
                                                &nbsp;&nbsp;{ recommended_user_profiles[profile]['age'].replace('-', ' - ') }
                                            </TableCell>
                                        </TableRow> }

                                        <TableRow>
                                            <TableCell><b>Househ. Income</b></TableCell>
                                            <TableCell sx={{
                                                'display': 'flex',
                                                'alignItems': 'center',
                                                'width': '220px',
                                            }}>
                                                <Paid/>
                                                &nbsp;&nbsp;{ recommended_user_profiles[profile]['hhi'] }
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell><b>Impressions</b></TableCell>
                                            <TableCell sx={{
                                                'display': 'flex',
                                                'alignItems': 'center',
                                                'width': '220px',
                                            }}>
                                                <AdUnits/>
                                                &nbsp;&nbsp;{ new Intl.NumberFormat().format(Number(recommended_user_profiles[profile]['imps'] )) }
                                            </TableCell>
                                        </TableRow>

                                        { recommended_user_profiles[profile]['males'] && <TableRow>
                                            <TableCell><b>Gender Distrib.</b></TableCell>
                                            <TableCell sx={{
                                                'display': 'flex',
                                                'alignItems': 'center',
                                                'width': '220px',
                                            }}>
                                                <Male/>&nbsp;&nbsp;&nbsp;{ recommended_user_profiles[profile]['males'] }%
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <Female/>&nbsp;&nbsp;&nbsp;{ recommended_user_profiles[profile]['females'] }%
                                            </TableCell>
                                        </TableRow> }

                                        { device_type !== 'Connected TV' && recommended_user_profiles[profile]['android'] !== null && <TableRow>
                                            <TableCell><b>OS Distrib.</b></TableCell>
                                            <TableCell sx={{
                                                'display': 'flex',
                                                'alignItems': 'center',
                                                'width': '220px',
                                            }}>
                                                <Android/>&nbsp;&nbsp;&nbsp;{ recommended_user_profiles[profile]['android'] }%
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <Apple/>&nbsp;&nbsp;&nbsp;{ recommended_user_profiles[profile]['ios'] }%
                                            </TableCell>
                                        </TableRow> }
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>

                        { recommended_user_profiles && <MobileStepper
                            steps={ recommended_user_profiles.length }
                            activeStep={ profile }
                            position='static'
                            variant='text'
                            style={{
                                'fontFamily': 'Roboto',
                                'width': '432px',
                                'textAlign': 'center',
                                'padding': 0,
                                'paddingTop': '8px',
                            }}
                            nextButton={
                                <Button
                                    size='small'
                                    disabled={ is_content_loading || is_data_loading || !recommended_user_profiles || profile === recommended_user_profiles.length - 1}
                                    onClick={ () => setProfile(profile + 1) }
                                >
                                    Next
                                    <KeyboardArrowRight/> 
                                </Button>
                            } backButton={
                                <Button
                                    size='small'
                                    disabled={ profile === 0 || is_content_loading || is_data_loading || !recommended_user_profiles }
                                    onClick={ () => setProfile(profile - 1) }
                                >
                                    <KeyboardArrowLeft/> Back
                                </Button>
                            }/> }
                    </Grid> }

                    { main_topic === 'apps' && !recommended_app_categories && no_data_alert }
                    { main_topic === 'apps' && recommended_app_categories && <Grid
                        item
                        xs={ 8 }
                        mt={ 1 }
                        pl={ 2 }
                        py={ 2 }
                    >
                        <Card variant='outlined' sx={{
                            'backgroundColor': 'rgba(255, 255, 255, 0.5)',
                            'width': '432px',
                            'height': '335px',
                        }}>
                            <CardHeader
                                subheader='Top App Categories'
                            />

                            <CardContent sx={{
                                'maxHeight': '237px',
                                'overflowY': 'auto',
                            }}>
                                <Table size='small' style={{ 'borderCollapse': 'separate' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Code&nbsp;&nbsp;&nbsp;</TableCell>
                                            <TableCell>Category</TableCell>
                                            <TableCell>{ kpi }</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        { recommended_app_categories.map((app_category, i) => <TableRow key={ i }>
                                            <TableCell sx={{
                                                'wordWrap': 'none',
                                                'whiteSpace': 'pre',
                                            }}>
                                                { app_category['app_cat_codes'] }
                                            </TableCell>

                                            <TableCell sx={{
                                                'wordWrap': 'none',
                                                'whiteSpace': 'pre',
                                            }}>
                                                { app_category['app_cat_names'] }
                                            </TableCell>

                                            <TableCell sx={{
                                                'color': i === 0 ? '#4caf50' : 'initial',
                                                'whiteSpace': 'pre',
                                            }}>
                                                <b>{ app_category[kpi_lc] }</b>
                                            </TableCell>
                                        </TableRow>) }
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid> }

                    { main_topic === 'pois' && !recommended_poi_categories && no_data_alert }
                    { main_topic === 'pois' && recommended_poi_categories && <Grid
                        item
                        xs={ 8 }
                        mt={ 1 }
                        pl={ 2 } 
                        py={ 2 }
                    >
                        <Card variant='outlined' sx={{
                            'backgroundColor': 'rgba(255, 255, 255, 0.5)',
                            'width': '432px',
                            'height': '335px',
                        }}>
                            <CardHeader
                                subheader='Top Location'
                            />

                            <CardContent sx={{
                                'maxHeight': '237px',
                                'overflowY': 'auto',
                            }}>
                                <Table size='small' style={{ 'borderCollapse': 'separate' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Category</TableCell>
                                            <TableCell>{ kpi }</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        { recommended_poi_categories.map((poi_category, i) => <TableRow key={ i }>
                                            <TableCell>
                                                { poi_category['category'] }
                                            </TableCell>

                                            <TableCell sx={{
                                                'color': i === 0 ? '#4caf50' : 'initial',
                                                'whiteSpace': 'pre',
                                            }}>
                                                <b>{ poi_category[kpi_lc] }</b>
                                            </TableCell>
                                        </TableRow>) }
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid> }
                </Grid>
            </Grid>
        </Grid>
    </React.Fragment>;
}

export default App;
